

















































import { Component, Vue } from "vue-property-decorator";
import { abilities } from "@/plugins/casl/abilities";
import * as navbarHelper from "@/utils/navbarhelper";
import { MenuButton } from "@/utils/navbarhelper/types";

// TODO create sub navbars and use main navbar for topics like users, permissions, configurator, shop

/**
 * AdminNavbar component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class AdminNavbar extends Vue {
    // Routes that the user has access to
    private filteredMenuButtons: Array<MenuButton> = [];

    /**
     * Function will be automatic called by Vue.js (see vue lifecycle)
     * Function fetches data, initialize variables etc.
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private created() {
        this.filterMenuButtons();
        abilities.on("updated", () => {
            this.filterMenuButtons();
        });
    }

    /**
     * Saves the routes in filteredMenuButtons that the user has access to
     *
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private filterMenuButtons() {
        const menuButtons: Array<MenuButton> = [
            {
                to: { name: "AdminPanelShopDealManagement" },
                exact: false,
                text: "shop.deal",
            },
            {
                to: { name: "AdminPanelShopArticleManagement" },
                exact: true,
                text: "shop.article",
            },
            {
                to: { name: "AdminPanelShopArticleCategoryManagement" },
                exact: false,
                text: "shop.article.category",
            },
            {
                to: { name: "AdminPanelShopArticleGroupManagement" },
                exact: false,
                text: "shop.article.group",
            },
            {
                to: { name: "AdminPanelShopArticleProducerManagement" },
                exact: false,
                text: "shop.article.producer",
            },
            {
                to: { name: "AdminPanelShopArticlePriceClassManagement" },
                exact: false,
                text: "shop.article.priceClass",
            },
            {
                to: { name: "AdminPanelPropertyManagement" },
                exact: false,
                text: "shop.article.property",
            },
            {
                to: {
                    name: "AdminPanelUserCompanyManagement",
                },
                exact: true,
                text: "user.company",
            },
            {
                to: {
                    name: "AdminPanelUserManagement",
                },
                exact: true,
                text: "user",
            },
            {
                to: {
                    name: "AdminPanelUserPermissionGroupManagement",
                },
                exact: true,
                text: "user.permissionGroup",
            },
            {
                to: {
                    name: "AdminAnalyticsManagement",
                },
                exact: true,
                text: this.$tc("admin.analytics"),
            },
        ];

        this.filteredMenuButtons = navbarHelper.filterMenuButtons(menuButtons);
    }
}
