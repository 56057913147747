









import { Component, Vue } from "vue-property-decorator";
import AdminNavbar from "@/components/admin/AdminNavbar.vue";

/**
 * AdminPanel view
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        AdminNavbar,
    },
})
export default class AdminPanel extends Vue {}
